import Close from '@icons/close.svg'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import AsyncSelector from 'src/components/selectors/AsyncSelector'
import { useSearchForSystemStrings } from 'src/query/fdvu/system'
import IconButton from 'src/ui-elements/button/IconButton'
import { ISystemSyntaxHierarchy } from '../../../../../service/OrgTypes'
import { useMultipleKeysTranslation } from '../../../../../service/useMultipleKeysTranslation'

export interface SyntaxSearchFilterLevelProps {
  systemSyntaxItem: ISystemSyntaxHierarchy
  searchFilters: string[]
  onChange?: (value: string[]) => void
  disabled?: boolean
  showHelpText?: boolean
  small?: boolean
}

const SyntaxSearchFilterLevel = ({
  systemSyntaxItem,
  searchFilters,
  disabled,
  onChange,
  showHelpText,
  small,
}: SyntaxSearchFilterLevelProps) => {
  const { t } = useMultipleKeysTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  const { data: systemStrings = [], isLoading } = useSearchForSystemStrings(
    searchTerm,
    systemSyntaxItem.id,
    systemSyntaxItem.system_setting_id,
  )

  return (
    <div className={'flex items-center justify-between'}>
      <div
        key={systemSyntaxItem.id}
        className={twMerge(
          'flex flex-1 justify-start items-center',
          small ? 'gap-0.5' : 'gap-1 py-1',
        )}
      >
        <div className="text text-blue-root whitespace-nowrap">{`${systemSyntaxItem?.syntax_string ?? systemSyntaxItem?.constant_string ?? ''}: `}</div>
        <div className="flex flex-wrap items-center gap-1">
          {searchFilters.map((filter, index) => (
            <div
              className={twMerge(
                'flex justify-between items-center px-2 bg-white border border-grey-300 rounded gap-2',
                small ? 'h-5' : 'py-1',
              )}
              key={filter}
            >
              <div>{filter}</div>
              {!disabled && (
                <IconButton
                  icon={<Close className="fill-red" />}
                  onClick={() => {
                    onChange?.(searchFilters?.filter((_, i) => i !== index))
                  }}
                />
              )}
            </div>
          ))}
          {!disabled && (
            <AsyncSelector
              data={systemStrings}
              isLoading={isLoading}
              onSearch={setSearchTerm}
              onSelect={(value) => onChange?.([...searchFilters, value])}
            />
          )}
        </div>
      </div>
      {showHelpText && (
        <p className="flex-[2] text-xs font-normal italic">
          {searchFilters.length === 0 && t('search_filter_explained_empty')}
          {searchFilters.length === 1 && t('search_filter_explained')}
          {searchFilters.length > 1 && t('search_filter_explained_multiple')}
        </p>
      )}
    </div>
  )
}

export default SyntaxSearchFilterLevel
